/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import Link, { LinkProps } from 'next/link';
import { FC, ReactNode } from 'react';
import { sxs } from '@utils/mui/sx';

type UnderLineOption = 'never' | 'hover' | 'always';

const getLinkSx = (underline: UnderLineOption): SxProps => ({
    '&:hover': {
        textDecoration: underline !== 'never' ? 'underline' : 'none',
    },
    color: 'primary.main',
    fontSize: 'inherit',
    fontWeight: 'medium',
    textDecoration: underline !== 'always' ? 'none' : 'underline',
});

interface PlainLinkProps extends LinkProps {
    children?: ReactNode;
    className?: string;
    dataTestId?: string;
    href: string;
    openInNewTab?: boolean;
    rel?: string;
    sx?: SxProps<Theme>;
    underline?: UnderLineOption;
}

const PlainLink: FC<PlainLinkProps> = ({
    children,
    className,
    dataTestId,
    href: providedHref,
    openInNewTab,
    onClick,
    rel,
    sx = {},
    underline = 'hover',
    ...linkProps
}) => {
    // make sure we pass the right format as Link from i18n will prepend the language acronym if / is not provided
    const href =
        providedHref.charAt(0) !== '/' &&
        !providedHref.startsWith('http') &&
        !providedHref.startsWith('www') &&
        !providedHref.startsWith('mailto:')
            ? `/${providedHref}`
            : providedHref;

    const linkSx = getLinkSx(underline);

    return (
        <Link
            href={href}
            rel={`noreferrer ${rel ?? ''}`}
            target={openInNewTab ? '_blank' : undefined}
            {...linkProps}
        >
            <Typography
                className={className}
                component="span"
                data-test-id={dataTestId}
                onClick={onClick}
                sx={sxs([linkSx, sx])}
            >
                {children}
            </Typography>
        </Link>
    );
};

export default PlainLink;
