export const LEGIFRANCE_URL = 'https://www.legifrance.gouv.fr';
export const CONTACT_APPOINTMENT_URL = 'https://livv.eu/tarifs-et-abonnements#rdv';
export const CONTACT_URL = 'https://livv.eu/contactez-nous';

export const NON_PAID_USER__UNRESTRICTED_URL = [
    '/offers',
    '/payment/success',
    '/payment/failure',
    '/profile',
];
export const PAID_USER_RESTRICTED_URL = ['/offer'];
